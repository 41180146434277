var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('span',{staticClass:"screen-box"},[_vm._v(_vm._s(_vm.screen + 1))]),_c('div',{staticClass:"bubble above full",class:{
      hidden: !_vm.showTopBubble,
      showfa: _vm.showFA,
      'showpc right': _vm.showPC,
      showdemo: _vm.showDemo || _vm.showXDemo,
    }},[_c('b',{domProps:{"innerHTML":_vm._s(_vm.bubbleHeader)}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.bubbleText)}})]),_c('div',{staticClass:"bubble left nextArrow",class:{ textPage: _vm.isPage, hidden: !_vm.showContinueBubble }},[_vm._v(" "+_vm._s(_vm.bubbleNext)+" ")]),(_vm.showDemo)?_c('div',{staticClass:"test-fs demo"},[_c('div',{staticClass:"leftside"},[_c('div',{staticClass:"prev",class:{ hidden: !_vm.showPrevious },on:{"click":function($event){return _vm.previous()}}})]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"questions",class:{ highlight: _vm.highlightQuestions }},[_c('div',[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q1)}})]),_c('div',[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q2)}})]),_c('div',[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q3)}})]),_c('div',[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q4)}})])])]),_c('div',{staticClass:"rightside"},[_c('div',{staticClass:"next",class:{ hidden: !_vm.showNext },on:{"click":function($event){return _vm.next()}}})])]):_vm._e(),(_vm.showXDemo)?_c('div',{staticClass:"test-fs demo"},[_c('div',{staticClass:"leftside"},[_c('div',{staticClass:"prev",class:{ hidden: !_vm.showPrevious },on:{"click":function($event){return _vm.previous()}}})]),_c('div',{staticClass:"middle",class:{ wrong: _vm.showIncorrectAnimation }},[_c('div',{staticClass:"questions",class:{ highlight: _vm.highlightQuestions }},[_c('div',{class:{ highlight: _vm.highlightQ1 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q1)}})]),_c('div',{class:{ highlight: _vm.highlightQ2 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q2)}})]),_c('div',{class:{ highlight: _vm.highlightQ3 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q3)}})]),_c('div',{class:{ highlight: _vm.highlightQ4 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q4)}})])]),_c('img',{attrs:{"src":require("../../assets/img/line.png")}}),_c('div',{staticClass:"answers",class:{ highlight: _vm.highlightAnswers }},[_c('div',{staticClass:"a1",class:{
            highlight: _vm.highlightA1,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('span',{on:{"click":function($event){return _vm.answer(1)}}},[_vm._v(_vm._s(_vm.translate(_vm.item.a1)))])]),_c('div',{staticClass:"a2",class:{
            highlight: _vm.highlightA2,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('span',{on:{"click":function($event){return _vm.answer(2)}}},[_vm._v(_vm._s(_vm.translate(_vm.item.a2)))])]),_c('div',{staticClass:"a3",class:{
            highlight: _vm.highlightA3,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('span',{on:{"click":function($event){return _vm.answer(3)}}},[_vm._v(_vm._s(_vm.translate(_vm.item.a3)))])]),_c('div',{staticClass:"a4",class:{
            highlight: _vm.highlightA4,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('span',{on:{"click":function($event){return _vm.answer(4)}}},[_vm._v(_vm._s(_vm.translate(_vm.item.a4)))])])])]),_c('div',{staticClass:"rightside"},[_c('div',{staticClass:"next",class:{ hidden: !_vm.showNext },on:{"click":function($event){return _vm.next()}}})])]):_vm._e(),(_vm.showFS)?_c('div',{staticClass:"test-fs"},[_c('div',{staticClass:"leftside"},[_c('div',{staticClass:"refresh",class:{ hidden: !_vm.showReplay },on:{"click":function($event){return _vm.replay()}}}),_c('div',{staticClass:"prev",class:{ hidden: !_vm.showPrevious },on:{"click":function($event){return _vm.previous()}}})]),_c('div',{staticClass:"middle",class:{ wrong: _vm.showIncorrectAnimation }},[_c('div',{staticClass:"questions",class:{ highlight: _vm.highlightQuestions }},[_c('div',{class:{ highlight: _vm.highlightQ1 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q1)}})]),_c('div',{class:{ highlight: _vm.highlightQ2 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q2)}})]),_c('div',{class:{ highlight: _vm.highlightQ3 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q3)}})]),_c('div',{class:{ highlight: _vm.highlightQ4 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q4)}})]),_c('div',{staticClass:"result",class:_vm.highlightColorQuestionmark()},[_c('img',{attrs:{"src":_vm.resolve(_vm.questionMarkImage)}})])]),_c('img',{attrs:{"src":require("../../assets/img/line.png")}}),_c('div',{staticClass:"answers",class:{ highlight: _vm.highlightAnswers }},[_c('div',{staticClass:"a1",class:{
            highlight: _vm.highlightA1,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a1)},on:{"click":function($event){return _vm.answer(1)}}})]),_c('div',{staticClass:"a2",class:{
            highlight: _vm.highlightA2,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a2)},on:{"click":function($event){return _vm.answer(2)}}})]),_c('div',{staticClass:"a3",class:{
            highlight: _vm.highlightA3,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a3)},on:{"click":function($event){return _vm.answer(3)}}})]),_c('div',{staticClass:"a4",class:{
            highlight: _vm.highlightA4,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a4)},on:{"click":function($event){return _vm.answer(4)}}})])])]),_c('div',{staticClass:"rightside"},[_c('div',{staticClass:"next",class:{ hidden: !_vm.showNext },on:{"click":function($event){return _vm.next()}}},[_c('div',{staticClass:"hidden",class:{ highlight: _vm.highlightNext }})])])]):_vm._e(),(_vm.showFA)?_c('div',{staticClass:"test-fa"},[_c('div',{staticClass:"leftside"},[_c('div',{staticClass:"refresh",class:{ hidden: !_vm.showReplay },on:{"click":function($event){return _vm.replay()}}}),_c('div',{staticClass:"prev",class:{ hidden: !_vm.showPrevious },on:{"click":function($event){return _vm.previous()}}})]),_c('div',{staticClass:"middle",class:{ wrong: _vm.showIncorrectAnimation }},[_c('div',{staticClass:"questions",class:{ highlight: _vm.highlightQuestions }},[_c('div',{class:{ highlight: _vm.highlightQ1 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q1)}})]),_c('div',{class:{ highlight: _vm.highlightQ2 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q2)}})]),_c('div',{class:{ highlight: _vm.highlightQ3 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q3)}})]),_c('div',{staticClass:"result",class:_vm.highlightColorQuestionmark()},[_c('img',{attrs:{"src":_vm.resolve(_vm.questionMarkImage)}})])]),_c('img',{attrs:{"src":require("../../assets/img/line.png")}}),_c('div',{staticClass:"answers",class:{ highlight: _vm.highlightAnswers }},[_c('div',{staticClass:"a1",class:{
            highlight: _vm.highlightA1,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a1)},on:{"click":function($event){return _vm.answer(1)}}})]),_c('div',{staticClass:"a2",class:{
            highlight: _vm.highlightA2,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a2)},on:{"click":function($event){return _vm.answer(2)}}})]),_c('div',{staticClass:"a3",class:{
            highlight: _vm.highlightA3,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a3)},on:{"click":function($event){return _vm.answer(3)}}})]),_c('div',{staticClass:"a4",class:{
            highlight: _vm.highlightA4,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a4)},on:{"click":function($event){return _vm.answer(4)}}})])])]),_c('div',{staticClass:"rightside"},[_c('div',{staticClass:"next",class:{ hidden: !_vm.showNext },on:{"click":function($event){return _vm.next()}}},[_c('div',{staticClass:"hidden",class:{ highlight: _vm.highlightNext }})])])]):_vm._e(),(_vm.showPC)?_c('div',{staticClass:"test-pc"},[_c('div',{staticClass:"leftside"},[_c('div',{staticClass:"refresh",class:{ hidden: !_vm.showReplay },on:{"click":function($event){return _vm.replay()}}}),_c('div',{staticClass:"prev",class:{ hidden: !_vm.showPrevious },on:{"click":function($event){return _vm.previous()}}})]),_c('div',{staticClass:"middle",class:{ wrong: _vm.showIncorrectAnimation }},[_c('div',{staticClass:"questions",class:{ highlight: _vm.highlightQuestions }},[_c('div',{class:{ highlight: _vm.highlightQ1 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q1)}})]),_c('div',{class:{ highlight: _vm.highlightQ2 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q2)}})]),_c('div',{class:{ highlight: _vm.highlightQ3 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q3)}})]),_c('div',{class:{ highlight: _vm.highlightQ4 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q4)}})]),_c('div',{class:{ highlight: _vm.highlightQ5 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q5)}})]),_c('div',{class:{ highlight: _vm.highlightQ6 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q6)}})]),_c('div',{class:{ highlight: _vm.highlightQ7 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q7)}})]),_c('div',{class:{ highlight: _vm.highlightQ8 }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.q8)}})]),_c('div',{staticClass:"result",class:_vm.highlightColorQuestionmark()},[_c('img',{attrs:{"src":_vm.resolve(_vm.questionMarkImage)}})])]),_c('img',{attrs:{"src":require("../../assets/img/line.png")}}),_c('div',{staticClass:"answers",class:{ highlight: _vm.highlightAnswers }},[_c('div',{staticClass:"a1",class:{
            highlight: _vm.highlightA1,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a1)},on:{"click":function($event){return _vm.answer(1)}}})]),_c('div',{staticClass:"a2",class:{
            highlight: _vm.highlightA2,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a2)},on:{"click":function($event){return _vm.answer(2)}}})]),_c('div',{staticClass:"a3",class:{
            highlight: _vm.highlightA3,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a3)},on:{"click":function($event){return _vm.answer(3)}}})]),_c('div',{staticClass:"a4",class:{
            highlight: _vm.highlightA4,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a4)},on:{"click":function($event){return _vm.answer(4)}}})]),_c('div',{staticClass:"a5",class:{
            highlight: _vm.highlightA5,
            green: _vm.hasCorrectAnswer(),
            orange: !_vm.hasCorrectAnswer(),
          }},[_c('img',{attrs:{"src":_vm.resolve(_vm.item.a5)},on:{"click":function($event){return _vm.answer(5)}}})])])]),_c('div',{staticClass:"rightside"},[_c('div',{staticClass:"next",class:{ hidden: !_vm.showNext },on:{"click":function($event){return _vm.next()}}},[_c('div',{staticClass:"hidden",class:{ highlight: _vm.highlightNext }})])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }