<template>
  <div class="container">
    <span class="screen-box">{{ screen + 1 }}</span>
    <div
      class="bubble above full"
      v-bind:class="{
        hidden: !showTopBubble,
        showfa: showFA,
        'showpc right': showPC,
        showdemo: showDemo || showXDemo,
      }"
    >
      <b v-html="bubbleHeader"></b>
      <span v-html="bubbleText"></span>
    </div>

    <div
      class="bubble left nextArrow"
      v-bind:class="{ textPage: isPage, hidden: !showContinueBubble }"
    >
      {{ bubbleNext }}
    </div>

    <!-- Test Demo -->
    <div class="test-fs demo" v-if="showDemo">
      <div class="leftside">
        <div
          class="prev"
          v-bind:class="{ hidden: !showPrevious }"
          v-on:click="previous()"
        ></div>
      </div>
      <div class="middle">
        <div class="questions" v-bind:class="{ highlight: highlightQuestions }">
          <div><img :src="resolve(item.q1)" /></div>
          <div><img :src="resolve(item.q2)" /></div>
          <div><img :src="resolve(item.q3)" /></div>
          <div><img :src="resolve(item.q4)" /></div>
        </div>
      </div>
      <div class="rightside">
        <div
          class="next"
          v-bind:class="{ hidden: !showNext }"
          v-on:click="next()"
        ></div>
      </div>
    </div>

    <!-- Test XDemo -->
    <div class="test-fs demo" v-if="showXDemo">
      <div class="leftside">
        <div
          class="prev"
          v-bind:class="{ hidden: !showPrevious }"
          v-on:click="previous()"
        ></div>
      </div>
      <div class="middle" v-bind:class="{ wrong: showIncorrectAnimation }">
        <div class="questions" v-bind:class="{ highlight: highlightQuestions }">
          <div v-bind:class="{ highlight: highlightQ1 }">
            <img :src="resolve(item.q1)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ2 }">
            <img :src="resolve(item.q2)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ3 }">
            <img :src="resolve(item.q3)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ4 }">
            <img :src="resolve(item.q4)" />
          </div>
        </div>
        <img src="../../assets/img/line.png" />
        <div class="answers" v-bind:class="{ highlight: highlightAnswers }">
          <div
            class="a1"
            v-bind:class="{
              highlight: highlightA1,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <span v-on:click="answer(1)">{{ translate(item.a1) }}</span>
          </div>
          <div
            class="a2"
            v-bind:class="{
              highlight: highlightA2,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <span v-on:click="answer(2)">{{ translate(item.a2) }}</span>
          </div>
          <div
            class="a3"
            v-bind:class="{
              highlight: highlightA3,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <span v-on:click="answer(3)">{{ translate(item.a3) }}</span>
          </div>
          <div
            class="a4"
            v-bind:class="{
              highlight: highlightA4,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <span v-on:click="answer(4)">{{ translate(item.a4) }}</span>
          </div>
        </div>
      </div>
      <div class="rightside">
        <div
          class="next"
          v-bind:class="{ hidden: !showNext }"
          v-on:click="next()"
        ></div>
      </div>
    </div>

    <!-- Test FS -->
    <div class="test-fs" v-if="showFS">
      <div class="leftside">
        <div
          class="refresh"
          v-bind:class="{ hidden: !showReplay }"
          v-on:click="replay()"
        ></div>
        <div
          class="prev"
          v-bind:class="{ hidden: !showPrevious }"
          v-on:click="previous()"
        ></div>
      </div>
      <div class="middle" v-bind:class="{ wrong: showIncorrectAnimation }">
        <div class="questions" v-bind:class="{ highlight: highlightQuestions }">
          <div v-bind:class="{ highlight: highlightQ1 }">
            <img :src="resolve(item.q1)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ2 }">
            <img :src="resolve(item.q2)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ3 }">
            <img :src="resolve(item.q3)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ4 }">
            <img :src="resolve(item.q4)" />
          </div>
          <div v-bind:class="highlightColorQuestionmark()" class="result">
            <img :src="resolve(questionMarkImage)" />
          </div>
        </div>
        <img src="../../assets/img/line.png" />
        <div class="answers" v-bind:class="{ highlight: highlightAnswers }">
          <div
            class="a1"
            v-bind:class="{
              highlight: highlightA1,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a1)" v-on:click="answer(1)" />
          </div>
          <div
            class="a2"
            v-bind:class="{
              highlight: highlightA2,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a2)" v-on:click="answer(2)" />
          </div>
          <div
            class="a3"
            v-bind:class="{
              highlight: highlightA3,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a3)" v-on:click="answer(3)" />
          </div>
          <div
            class="a4"
            v-bind:class="{
              highlight: highlightA4,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a4)" v-on:click="answer(4)" />
          </div>
        </div>
      </div>
      <div class="rightside">
        <div
          class="next"
          v-bind:class="{ hidden: !showNext }"
          v-on:click="next()"
        >
          <div class="hidden" v-bind:class="{ highlight: highlightNext }" />
        </div>
      </div>
    </div>

    <!-- Test FA -->
    <div class="test-fa" v-if="showFA">
      <div class="leftside">
        <div
          class="refresh"
          v-bind:class="{ hidden: !showReplay }"
          v-on:click="replay()"
        ></div>
        <div
          class="prev"
          v-bind:class="{ hidden: !showPrevious }"
          v-on:click="previous()"
        ></div>
      </div>
      <div class="middle" v-bind:class="{ wrong: showIncorrectAnimation }">
        <div class="questions" v-bind:class="{ highlight: highlightQuestions }">
          <div v-bind:class="{ highlight: highlightQ1 }">
            <img :src="resolve(item.q1)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ2 }">
            <img :src="resolve(item.q2)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ3 }">
            <img :src="resolve(item.q3)" />
          </div>
          <div v-bind:class="highlightColorQuestionmark()" class="result">
            <img :src="resolve(questionMarkImage)" />
          </div>
        </div>
        <img src="../../assets/img/line.png" />
        <div class="answers" v-bind:class="{ highlight: highlightAnswers }">
          <div
            class="a1"
            v-bind:class="{
              highlight: highlightA1,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a1)" v-on:click="answer(1)" />
          </div>
          <div
            class="a2"
            v-bind:class="{
              highlight: highlightA2,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a2)" v-on:click="answer(2)" />
          </div>
          <div
            class="a3"
            v-bind:class="{
              highlight: highlightA3,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a3)" v-on:click="answer(3)" />
          </div>
          <div
            class="a4"
            v-bind:class="{
              highlight: highlightA4,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a4)" v-on:click="answer(4)" />
          </div>
        </div>
      </div>
      <div class="rightside">
        <div
          class="next"
          v-bind:class="{ hidden: !showNext }"
          v-on:click="next()"
        >
          <div class="hidden" v-bind:class="{ highlight: highlightNext }" />
        </div>
      </div>
    </div>

    <!-- Test PC -->
    <div class="test-pc" v-if="showPC">
      <div class="leftside">
        <div
          class="refresh"
          v-bind:class="{ hidden: !showReplay }"
          v-on:click="replay()"
        ></div>
        <div
          class="prev"
          v-bind:class="{ hidden: !showPrevious }"
          v-on:click="previous()"
        ></div>
      </div>
      <div class="middle" v-bind:class="{ wrong: showIncorrectAnimation }">
        <div class="questions" v-bind:class="{ highlight: highlightQuestions }">
          <div v-bind:class="{ highlight: highlightQ1 }">
            <img :src="resolve(item.q1)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ2 }">
            <img :src="resolve(item.q2)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ3 }">
            <img :src="resolve(item.q3)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ4 }">
            <img :src="resolve(item.q4)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ5 }">
            <img :src="resolve(item.q5)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ6 }">
            <img :src="resolve(item.q6)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ7 }">
            <img :src="resolve(item.q7)" />
          </div>
          <div v-bind:class="{ highlight: highlightQ8 }">
            <img :src="resolve(item.q8)" />
          </div>
          <div v-bind:class="highlightColorQuestionmark()" class="result">
            <img :src="resolve(questionMarkImage)" />
          </div>
        </div>
        <img src="../../assets/img/line.png" />
        <div class="answers" v-bind:class="{ highlight: highlightAnswers }">
          <div
            class="a1"
            v-bind:class="{
              highlight: highlightA1,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a1)" v-on:click="answer(1)" />
          </div>
          <div
            class="a2"
            v-bind:class="{
              highlight: highlightA2,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a2)" v-on:click="answer(2)" />
          </div>
          <div
            class="a3"
            v-bind:class="{
              highlight: highlightA3,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a3)" v-on:click="answer(3)" />
          </div>
          <div
            class="a4"
            v-bind:class="{
              highlight: highlightA4,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a4)" v-on:click="answer(4)" />
          </div>
          <div
            class="a5"
            v-bind:class="{
              highlight: highlightA5,
              green: hasCorrectAnswer(),
              orange: !hasCorrectAnswer(),
            }"
          >
            <img :src="resolve(item.a5)" v-on:click="answer(5)" />
          </div>
        </div>
      </div>
      <div class="rightside">
        <div
          class="next"
          v-bind:class="{ hidden: !showNext }"
          v-on:click="next()"
        >
          <div class="hidden" v-bind:class="{ highlight: highlightNext }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LPCATDemo",
  props: {
    phase: Number,
    translations: Object,
  },
  mounted() {
    this.init();
  },
  watch: {
    phase: function() {
      this.init();
    },
    translations : function() {
      this.setLanguage();
    }
  },
  methods: {
    init() {
      this.screen = this.phase == 1 ? 2 : 21;
      //this.screen = 53;

      this.setLanguage();
      this.showScreen();
    },
    skip() {
      this.$emit("training-ended", this.languageScore);
    },
    setLanguage() {
      switch (this.screen) {
        case 2:
          this.bubbleHeader = this.translations.fs1.demonstration.header;
          this.bubbleText = this.translations.fs1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 3:
          this.bubbleHeader = this.translations.fs1.explanation.header;
          this.bubbleText = this.translations.fs1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 4:
          this.bubbleHeader = this.translations.fs2.demonstration.header;
          this.bubbleText = this.translations.fs2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 5:
          this.bubbleHeader = this.translations.fs2.explanation.header;
          this.bubbleText = this.translations.fs2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 6:
          this.bubbleHeader = this.translations.fs3.demonstration.header;
          this.bubbleText = this.translations.fs3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 7:
          this.bubbleHeader = this.translations.fs3.explanation.header;
          this.bubbleText = this.translations.fs3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 8:
          this.bubbleHeader = this.translations.fa1.demonstration.header;
          this.bubbleText = this.translations.fa1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 9:
          this.bubbleHeader = this.translations.fa1.explanation.header;
          this.bubbleText = this.translations.fa1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 10:
          this.bubbleHeader = this.translations.fa2.demonstration.header;
          this.bubbleText = this.translations.fa2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 11:
          this.bubbleHeader = this.translations.fa2.explanation.header;
          this.bubbleText = this.translations.fa2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 12:
          this.bubbleHeader = this.translations.fa3.demonstration.header;
          this.bubbleText = this.translations.fa3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 13:
          this.bubbleHeader = this.translations.fa3.explanation.header;
          this.bubbleText = this.translations.fa3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 14:
          this.bubbleHeader = this.translations.pc1.demonstration.header;
          this.bubbleText = this.translations.pc1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 15:
          this.bubbleHeader = this.translations.pc1.explanation.header;
          this.bubbleText = this.translations.pc1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 16:
          this.bubbleHeader = this.translations.pc2.demonstration.header;
          this.bubbleText = this.translations.pc2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 17:
          this.bubbleHeader = this.translations.pc2.explanation.header;
          this.bubbleText = this.translations.pc2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 18:
          this.bubbleHeader = this.translations.pc3.demonstration.header;
          this.bubbleText = this.translations.pc3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 19:
          this.bubbleHeader = this.translations.pc3.explanation.header;
          this.bubbleText = this.translations.pc3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 21:
          this.bubbleHeader = this.translations.questionType1Review.header;
          this.bubbleText = this.translations.questionType1Review.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 22:
          this.bubbleHeader = this.translations.questionType2Review.header;
          this.bubbleText = this.translations.questionType2Review.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 23:
          this.bubbleHeader = this.translations.questionType3Review.header;
          this.bubbleText = this.translations.questionType3Review.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 24:
          this.bubbleHeader = this.translations.explainShape.header;
          this.bubbleText = this.translations.explainShape.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 25:
          this.bubbleHeader = this.translations.explainSize.header;
          this.bubbleText = this.translations.explainSize.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 26:
          this.bubbleHeader = this.translations.explainDirectionPosition.header;
          this.bubbleText = this.translations.explainDirectionPosition.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 27:
          this.bubbleHeader = this.translations.explainNumber.header;
          this.bubbleText = this.translations.explainNumber.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 28:
          this.bubbleHeader = this.translations.explainShadingPattern.header;
          this.bubbleText = this.translations.explainShadingPattern.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 29:
          this.bubbleHeader = this.translations.exampleShapeChanging.header;
          this.bubbleText = this.translations.exampleShapeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 30:
          this.bubbleHeader = this.translations.exampleSizeChanging.header;
          this.bubbleText = this.translations.exampleSizeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 31:
          this.bubbleHeader = this.translations.exampleShapeSizeChanging.header;
          this.bubbleText = this.translations.exampleShapeSizeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 32:
          this.bubbleHeader = this.translations.examplePositionChanging.header;
          this.bubbleText = this.translations.examplePositionChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 33:
          this.bubbleHeader = this.translations.exampleNumberFiguresPartsChanging.header;
          this.bubbleText = this.translations.exampleNumberFiguresPartsChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 34:
          this.bubbleHeader = this.translations.exampleShadingChanging.header;
          this.bubbleText = this.translations.exampleShadingChanging.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 35:
          this.bubbleHeader = this.translations.languageFeedback1.question.header;
          this.bubbleText = this.translations.languageFeedback1.question.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 36:
          this.bubbleHeader = this.translations.languageFeedback2.question.header;
          this.bubbleText = this.translations.languageFeedback2.question.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 37:
          this.bubbleHeader = this.translations.languageFeedback3.question.header;
          this.bubbleText = this.translations.languageFeedback3.question.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 38:
          this.bubbleHeader = this.translations.languageFeedback4.question.header;
          this.bubbleText = this.translations.languageFeedback4.question.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 39:
          this.bubbleHeader = this.translations.training1.demonstration.header;
          this.bubbleText = this.translations.training1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 40:
          this.bubbleHeader = this.translations.training1.explanation.header;
          this.bubbleText = this.translations.training1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 41:
          this.bubbleHeader = this.translations.training2.demonstration.header;
          this.bubbleText = this.translations.training2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 42:
          this.bubbleHeader = this.translations.training2.explanation.header;
          this.bubbleText = this.translations.training2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 43:
          this.bubbleHeader = this.translations.training3.demonstration.header;
          this.bubbleText = this.translations.training3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 44:
          this.bubbleHeader = this.translations.training3.explanation.header;
          this.bubbleText = this.translations.training3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 45:
          this.bubbleHeader = this.translations.training4.demonstration.header;
          this.bubbleText = this.translations.training4.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 46:
          this.bubbleHeader = this.translations.training4.explanation.header;
          this.bubbleText = this.translations.training4.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 47:
          this.bubbleHeader = this.translations.training5.demonstration.header;
          this.bubbleText = this.translations.training5.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 48:
          this.bubbleHeader = this.translations.training5.explanation.header;
          this.bubbleText = this.translations.training5.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 49:
          this.bubbleHeader = this.translations.training6.demonstration.header;
          this.bubbleText = this.translations.training6.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 50:
          this.bubbleHeader = this.translations.training6.explanation.header;
          this.bubbleText = this.translations.training6.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
        case 51:
          this.bubbleHeader = this.translations.training7.demonstration.header;
          this.bubbleText = this.translations.training7.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          break;
        case 52:
          this.bubbleHeader = this.translations.training7.explanation.header;
          this.bubbleText = this.translations.training7.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          break;
      }
    },
    translate(text) {
      if (text === "Position") {
        return this.translations.position;
      } else if (text === "Shape") {
        return this.translations.shape;
      } else if (text === "Number") {
        return this.translations.number;
      } else if (text === "Size") {
        return this.translations.size;
      }
      return text;
    },
    showScreen() {
      this.reset();
      this.onAnswer = false;

      switch (this.screen) {
        // case 0:
        // this.header = this.translations.intro.header
        // this.text = this.translations.intro.text
        // this.bubbleNext = this.translations.continue.next
        // this.isPage = true
        // setTimeout(() => {
        //   this.showContinueBubble = true
        // }, 700)
        // break
        // this.screen = this.screen + 1
        // case 1:
        // this.bubbleHeader = this.translations.animation.header
        // this.bubbleText = this.translations.animation.text
        // this.bubbleNext = this.translations.continue.next
        // this.showCorrectAnswer = true
        // this.showTopBubble = true
        // this.selectedAnswer = 2
        // this.showItem(this.items[0])
        // setTimeout(() => {
        //   this.showContinueBubble = true
        // }, 700)
        // break
        // this.screen = this.screen + 1
        case 2:
          this.bubbleHeader = this.translations.fs1.demonstration.header;
          this.bubbleText = this.translations.fs1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.showItem(this.items[0]);
          this.showFSDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs1.demonstration.answers);
          };
          break;
        case 3:
          this.showItem(this.items[0]);
          this.bubbleHeader = this.translations.fs1.explanation.header;
          this.bubbleText = this.translations.fs1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 2;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs1.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA2 = true;
            this.questionMarkImage = this.item.a2;
          }, 700);
          break;
        case 4:
          this.bubbleHeader = this.translations.fs2.demonstration.header;
          this.bubbleText = this.translations.fs2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.showItem(this.items[1]);
          this.showFSDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs2.demonstration.answers);
          };
          break;
        case 5:
          this.showItem(this.items[1]);
          this.bubbleHeader = this.translations.fs2.explanation.header;
          this.bubbleText = this.translations.fs2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 2;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs2.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA2 = true;
            this.questionMarkImage = this.item.a2;
          }, 700);
          break;
        case 6:
          this.bubbleHeader = this.translations.fs3.demonstration.header;
          this.bubbleText = this.translations.fs3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.showItem(this.items[2]);
          this.showFSDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs3.demonstration.answers);
          };
          break;
        case 7:
          this.showItem(this.items[2]);
          this.bubbleHeader = this.translations.fs3.explanation.header;
          this.bubbleText = this.translations.fs3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 1;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fs3.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA1 = true;
            this.questionMarkImage = this.item.a1;
          }, 700);
          break;
        case 8:
          this.bubbleHeader = this.translations.fa1.demonstration.header;
          this.bubbleText = this.translations.fa1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[3]);
          this.showFADemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa1.demonstration.answers);
          };
          break;
        case 9:
          this.showItem(this.items[3]);
          this.bubbleHeader = this.translations.fa1.explanation.header;
          this.bubbleText = this.translations.fa1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 2;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa1.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA2 = true;
            this.questionMarkImage = this.item.a2;
          }, 700);
          break;
        case 10:
          this.bubbleHeader = this.translations.fa2.demonstration.header;
          this.bubbleText = this.translations.fa2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[4]);
          this.showFADemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa2.demonstration.answers);
          };
          break;
        case 11:
          this.showItem(this.items[4]);
          this.bubbleHeader = this.translations.fa2.explanation.header;
          this.bubbleText = this.translations.fa2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 4;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa2.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA4 = true;
            this.questionMarkImage = this.item.a4;
          }, 700);
          break;
        case 12:
          this.bubbleHeader = this.translations.fa3.demonstration.header;
          this.bubbleText = this.translations.fa3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[5]);
          this.showFADemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa3.demonstration.answers);
          };
          break;
        case 13:
          this.showItem(this.items[5]);
          this.bubbleHeader = this.translations.fa3.explanation.header;
          this.bubbleText = this.translations.fa3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 4;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.fa3.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA4 = true;
            this.questionMarkImage = this.item.a4;
          }, 700);
          break;
        case 14:
          this.bubbleHeader = this.translations.pc1.demonstration.header;
          this.bubbleText = this.translations.pc1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[6]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc1.demonstration.answers);
          };
          break;
        case 15:
          this.showItem(this.items[6]);
          this.bubbleHeader = this.translations.pc1.explanation.header;
          this.bubbleText = this.translations.pc1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 1;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc1.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA1 = true;
            this.questionMarkImage = this.item.a1;
          }, 700);
          break;
        case 16:
          this.bubbleHeader = this.translations.pc2.demonstration.header;
          this.bubbleText = this.translations.pc2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[7]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc2.demonstration.answers);
          };
          break;
        case 17:
          this.showItem(this.items[7]);
          this.bubbleHeader = this.translations.pc2.explanation.header;
          this.bubbleText = this.translations.pc2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 3;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc2.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA3 = true;
            this.questionMarkImage = this.item.a3;
          }, 700);
          break;
        case 18:
          this.bubbleHeader = this.translations.pc3.demonstration.header;
          this.bubbleText = this.translations.pc3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[8]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc3.demonstration.answers);
          };
          break;
        case 19:
          this.showItem(this.items[8]);
          this.bubbleHeader = this.translations.pc3.explanation.header;
          this.bubbleText = this.translations.pc3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = false;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 5;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.pc3.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA5 = true;
            this.questionMarkImage = this.item.a5;
          }, 700);
          break;
        case 20:
          this.$emit("training-ended", 0);
          break;
        case 21:
          this.bubbleHeader = this.translations.questionType1Review.header;
          this.bubbleText = this.translations.questionType1Review.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 2;
          this.showItem(this.items[0]);
          this.highlightQ = true;
          this.highlightA2 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.questionType1Review.answers);
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 22:
          this.bubbleHeader = this.translations.questionType2Review.header;
          this.bubbleText = this.translations.questionType2Review.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 2;
          this.showItem(this.items[3]);
          this.highlightQ = true;
          this.highlightA2 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.questionType2Review.answers);
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 23:
          this.bubbleHeader = this.translations.questionType3Review.header;
          this.bubbleText = this.translations.questionType3Review.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 1;
          this.showItem(this.items[6]);
          this.highlightQ = true;
          this.highlightA1 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.questionType3Review.answers);
          };
          break;
        case 24:
          this.bubbleHeader = this.translations.explainShape.header;
          this.bubbleText = this.translations.explainShape.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.showItem(this.items[9]);
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 25:
          this.bubbleHeader = this.translations.explainSize.header;
          this.bubbleText = this.translations.explainSize.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.showItem(this.items[10]);
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 26:
          this.bubbleHeader = this.translations.explainDirectionPosition.header;
          this.bubbleText = this.translations.explainDirectionPosition.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.showItem(this.items[11]);
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 27:
          this.bubbleHeader = this.translations.explainNumber.header;
          this.bubbleText = this.translations.explainNumber.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.showItem(this.items[12]);
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 28:
          this.bubbleHeader = this.translations.explainShadingPattern.header;
          this.bubbleText = this.translations.explainShadingPattern.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.showItem(this.items[13]);
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 29:
          this.bubbleHeader = this.translations.exampleShapeChanging.header;
          this.bubbleText = this.translations.exampleShapeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 2;
          this.showItem(this.items[14]);
          this.highlightQ = true;
          this.highlightA2 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.exampleShapeChanging.answers);
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 30:
          this.bubbleHeader = this.translations.exampleSizeChanging.header;
          this.bubbleText = this.translations.exampleSizeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 3;
          this.showItem(this.items[15]);
          this.highlightQ = true;
          this.highlightA3 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.exampleSizeChanging.answers);
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 31:
          this.bubbleHeader = this.translations.exampleShapeSizeChanging.header;
          this.bubbleText = this.translations.exampleShapeSizeChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 1;
          this.showItem(this.items[16]);
          this.highlightQ = true;
          this.highlightA1 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(
              this.translations.exampleShapeSizeChanging.answers
            );
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 32:
          this.bubbleHeader = this.translations.examplePositionChanging.header;
          this.bubbleText = this.translations.examplePositionChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 4;
          this.showItem(this.items[17]);
          this.highlightQ = true;
          this.highlightA4 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.examplePositionChanging.answers);
          };
          setTimeout(() => {
            this.showContinueBubble = true;
          }, 700);
          break;
        case 33:
          this.bubbleHeader = this.translations.exampleNumberFiguresPartsChanging.header;
          this.bubbleText = this.translations.exampleNumberFiguresPartsChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 5;
          this.showItem(this.items[18]);
          this.highlightQ = true;
          this.highlightA5 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(
              this.translations.exampleNumberFiguresPartsChanging.answers
            );
          };
          break;
        case 34:
          this.bubbleHeader = this.translations.exampleShadingChanging.header;
          this.bubbleText = this.translations.exampleShadingChanging.text;
          this.bubbleNext = this.translations.continue.next;
          this.showCorrectAnswer = true;
          this.showAnswer = true;
          this.showTopBubble = true;
          this.selectedAnswer = 1;
          this.showItem(this.items[19]);
          this.highlightQ = true;
          this.highlightA1 = true;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.exampleShadingChanging.answers);
          };
          break;
        case 35:
          this.showItem(this.items[20]);
          this.bubbleHeader = this.translations.languageFeedback1.question.header;
          this.bubbleText = this.translations.languageFeedback1.question.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.languageScore = 0;
          this.languageAnswered = false;
          this.onAnswer = () => {
            if (!this.languageAnswered) {
              this.languageAnswered = true;
              this.languageScore += this.hasCorrectAnswer() ? 25 : 0;
            }
            this.showAnswerBubble(this.translations.languageFeedback1.answers);
          };
          break;
        case 36:
          this.showItem(this.items[21]);
          this.bubbleHeader = this.translations.languageFeedback2.question.header;
          this.bubbleText = this.translations.languageFeedback2.question.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.languageAnswered = false;
          this.onAnswer = () => {
            if (!this.languageAnswered) {
              this.languageAnswered = true;
              this.languageScore += this.hasCorrectAnswer() ? 25 : 0;
            }
            this.showAnswerBubble(this.translations.languageFeedback2.answers);
          };
          break;
        case 37:
          this.showItem(this.items[22]);
          this.bubbleHeader = this.translations.languageFeedback3.question.header;
          this.bubbleText = this.translations.languageFeedback3.question.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.languageAnswered = false;
          this.onAnswer = () => {
            if (!this.languageAnswered) {
              this.languageAnswered = true;
              this.languageScore += this.hasCorrectAnswer() ? 25 : 0;
            }
            this.showAnswerBubble(this.translations.languageFeedback3.answers);
          };
          break;
        case 38:
          this.showItem(this.items[23]);
          this.bubbleHeader = this.translations.languageFeedback4.question.header;
          this.bubbleText = this.translations.languageFeedback4.question.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showCorrectAnswer = false;
          this.showAnswer = true;
          this.languageAnswered = false;
          this.onAnswer = () => {
            if (!this.languageAnswered) {
              this.languageAnswered = true;
              this.languageScore += this.hasCorrectAnswer() ? 25 : 0;
            }
            this.showAnswerBubble(this.translations.languageFeedback4.answers);
          };
          break;
        case 39:
          this.bubbleHeader = this.translations.training1.demonstration.header;
          this.bubbleText = this.translations.training1.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[24]);
          this.showFSDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training1.demonstration.answers);
          };
          break;
        case 40:
          this.showItem(this.items[24]);
          this.bubbleHeader = this.translations.training1.explanation.header;
          this.bubbleText = this.translations.training1.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 2;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training1.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA2 = true;
            this.questionMarkImage = this.item.a2;
          }, 700);
          break;
        case 41:
          this.bubbleHeader = this.translations.training2.demonstration.header;
          this.bubbleText = this.translations.training2.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[25]);
          this.showFADemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training2.demonstration.answers);
          };
          break;
        case 42:
          this.showItem(this.items[25]);
          this.bubbleHeader = this.translations.training2.explanation.header;
          this.bubbleText = this.translations.training2.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 3;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training2.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA3 = true;
            this.questionMarkImage = this.item.a3;
          }, 700);
          break;
        case 43:
          this.bubbleHeader = this.translations.training3.demonstration.header;
          this.bubbleText = this.translations.training3.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showContinueBubble = false;
          this.showItem(this.items[26]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training3.demonstration.answers);
          };
          break;
        case 44:
          this.showItem(this.items[26]);
          this.bubbleHeader = this.translations.training3.explanation.header;
          this.bubbleText = this.translations.training3.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 5;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training3.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA5 = true;
            this.questionMarkImage = this.item.a5;
          }, 700);
          break;
        case 45:
          this.bubbleHeader = this.translations.training4.demonstration.header;
          this.bubbleText = this.translations.training4.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showContinueBubble = false;
          this.showItem(this.items[27]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training4.demonstration.answers);
          };
          break;
        case 46:
          this.showItem(this.items[27]);
          this.bubbleHeader = this.translations.training4.explanation.header;
          this.bubbleText = this.translations.training4.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 4;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training4.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA4 = true;
            this.questionMarkImage = this.item.a4;
          }, 700);
          break;
        case 47:
          this.bubbleHeader = this.translations.training5.demonstration.header;
          this.bubbleText = this.translations.training5.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[28]);
          this.showFSDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training5.demonstration.answers);
          };
          break;
        case 48:
          this.showItem(this.items[28]);
          this.bubbleHeader = this.translations.training5.explanation.header;
          this.bubbleText = this.translations.training5.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 1;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training5.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA1 = true;
            this.questionMarkImage = this.item.a1;
          }, 700);
          break;
        case 49:
          this.bubbleHeader = this.translations.training6.demonstration.header;
          this.bubbleText = this.translations.training6.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[29]);
          this.showFADemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training6.demonstration.answers);
          };
          break;
        case 50:
          this.showItem(this.items[29]);
          this.bubbleHeader = this.translations.training6.explanation.header;
          this.bubbleText = this.translations.training6.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 4;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training6.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.showContinueBubble = true;
            this.highlightQ = true;
            this.highlightA4 = true;
            this.questionMarkImage = this.item.a4;
          }, 700);
          break;
        case 51:
          this.bubbleHeader = this.translations.training7.demonstration.header;
          this.bubbleText = this.translations.training7.demonstration.text;
          this.bubbleNext = this.translations.continue.explanation;
          this.showCorrectAnswer = false;
          this.showItem(this.items[30]);
          this.showPCDemo(this.ff);
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training7.demonstration.answers);
          };
          break;
        case 52:
          this.showItem(this.items[30]);
          this.bubbleHeader = this.translations.training7.explanation.header;
          this.bubbleText = this.translations.training7.explanation.text;
          this.bubbleNext = this.translations.continue.next;
          this.showTopBubble = true;
          this.showContinueBubble = false;
          this.showAnswer = true;
          this.showCorrectAnswer = false;
          this.selectedAnswer = 5;
          this.onAnswer = () => {
            this.showAnswerBubble(this.translations.training7.explanation.answers);
          };
          setTimeout(() => {
            this.showTopBubble = this.bubbleHeader !== "";
            this.highlightQ = true;
            this.highlightA5 = true;
            this.questionMarkImage = this.item.a5;
          }, 700);
          break;
        case 53:
          this.$emit("training-ended", this.languageScore);
          this.screen = 2;
          break;
      }
    },
    next() {
      if (this.showDemo) {
        this.screen = this.screen + 1;
        this.showScreen();
        return;
      }

      if (this.demoMode) {
        this.demoMode = false;
        this.reset();
        return;
      }

      if (this.hasCorrectAnswer()) {
        this.showContinueBubble = false;
        if (this.showCorrectAnswer) {
          this.highlightCorrectAnswer();
          this.showNext = false;
          setTimeout(() => {
            this.selectedAnswer = false;
            this.screen = this.screen + 1;
            this.showScreen();
            return;
          }, 1000);
          return;
        } else {
          this.selectedAnswer = false;
          this.screen = this.screen + 1;
          this.showScreen();
          return;
        }
      }

      if (!this.hasCorrectAnswer()) {
        this.highlightIncorrectAnswer();
        return;
      }
    },
    previous() {
      this.screen = this.screen - 1;
      this.showScreen();
    },
    showItem(item) {
      this.questionMarkImage = "result";
      this.showNext = true;
      this.showPrevious = this.screen > 2 && this.screen !== 21;
      this.item = item;
      this.showFA = item.item.startsWith("XFA");
      this.showFS = item.item.startsWith("XFS");
      this.showPC = item.item.startsWith("XPC");
      this.showDemo = item.item.startsWith("DEMO");
      this.showXDemo = item.item.startsWith("XDEMO");
    },
    replay() {
      this.showNext = true;
      this.showTopBubble = false;
      this.showContinueBubble = false;
      this.isPage = false;
      this.demoMode = false;
      this.highlightQ = false;
      this.highlightQ1 = false;
      this.highlightQ2 = false;
      this.highlightQ3 = false;
      this.highlightQ4 = false;
      this.highlightQ5 = false;
      this.highlightQ6 = false;
      this.highlightQ7 = false;
      this.highlightQ8 = false;
      this.highlightQ9 = false;
      this.highlightA1 = false;
      this.highlightA2 = false;
      this.highlightA3 = false;
      this.highlightA4 = false;
      this.highlightA5 = false;
      this.highlightAnswers = false;
      this.highlightQuestions = false;
      this.highlightNext = false;
      this.questionMarkImage = "result";
      this.selectedAnswer = false;
      if (this.showFS) {
        this.showFSDemo(this.ff);
      } else if (this.showFA) {
        this.showFADemo(this.ff);
      } else if (this.showPC) {
        this.showPCDemo(this.ff);
      } else {
        this.showTopBubble = true;
      }
    },
    reset() {
      this.showNext = true;
      this.showTopBubble = false;
      this.showContinueBubble = false;
      this.showFS = false;
      this.showFA = false;
      this.showPC = false;
      this.showDemo = false;
      this.showXDemo = false;
      this.showReplay = false;
      this.isPage = false;
      this.demoMode = false;
      this.highlightQ = false;
      this.highlightQ1 = false;
      this.highlightQ2 = false;
      this.highlightQ3 = false;
      this.highlightQ4 = false;
      this.highlightQ5 = false;
      this.highlightQ6 = false;
      this.highlightQ7 = false;
      this.highlightQ8 = false;
      this.highlightQ9 = false;
      this.highlightA1 = false;
      this.highlightA2 = false;
      this.highlightA3 = false;
      this.highlightA4 = false;
      this.highlightA5 = false;
      this.highlightAnswers = false;
      this.highlightQuestions = false;
      this.highlightNext = false;
      this.questionMarkImage = "result";
    },
    showFSDemo(interval) {
      this.demoMode = true;
      this.showReplay = false;
      this.showNext = false;
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.questionMarkImage = "result";
        this.highlightQ1 = true;
      }, interval * 1);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = true;
      }, interval * 2);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ2 = false;
        this.highlightQ3 = true;
      }, interval * 3);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ3 = false;
        this.highlightQ4 = true;
      }, interval * 4);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ4 = false;
        this.highlightQ = true;
      }, interval * 5);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ = false;
      }, interval * 6);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = true;
      }, interval * 7);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = false;
      }, interval * 8);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.showTopBubble = this.bubbleHeader !== "";
        this.showNext = true;
        this.showReplay = true;
        this.demoMode = false;
      }, interval * 9);
    },
    showFADemo(interval) {
      this.demoMode = true;
      this.showNext = false;
      this.showReplay = false;
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.questionMarkImage = "result";
        this.highlightQ1 = true;
        this.highlightQ2 = true;
      }, interval * 1);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = false;
        this.highlightQ3 = true;
        this.highlightQ = true;
      }, interval * 2);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = false;
        this.highlightQ3 = false;
        this.highlightQ = false;
      }, interval * 3);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = true;
        this.highlightQ2 = false;
        this.highlightQ3 = true;
        this.highlightQ = false;
      }, interval * 4);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = true;
        this.highlightQ3 = false;
        this.highlightQ = true;
      }, interval * 5);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = false;
        this.highlightQ3 = false;
        this.highlightQ = false;
      }, interval * 6);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ = false;
      }, interval * 7);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = true;
      }, interval * 8);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = false;
      }, interval * 9);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.showTopBubble = this.bubbleHeader !== "";
        this.showNext = true;
        this.showReplay = true;
        this.demoMode = false;
      }, interval * 10);
    },
    showPCDemo(interval) {
      this.demoMode = true;
      this.showNext = false;
      this.showReplay = false;
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = true;
        this.highlightQ2 = true;
        this.highlightQ3 = true;
      }, interval * 1);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ2 = false;
        this.highlightQ3 = false;
        this.highlightQ4 = true;
        this.highlightQ5 = true;
        this.highlightQ6 = true;
      }, interval * 2);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ4 = false;
        this.highlightQ5 = false;
        this.highlightQ6 = false;
        this.highlightQ7 = true;
        this.highlightQ8 = true;
        this.highlightQ = true;
      }, interval * 3);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.questionMarkImage = "result";
        this.highlightQ7 = false;
        this.highlightQ8 = false;
        this.highlightQ = false;
        this.highlightQ1 = true;
        this.highlightQ4 = true;
        this.highlightQ7 = true;
      }, interval * 4);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ1 = false;
        this.highlightQ4 = false;
        this.highlightQ7 = false;
        this.highlightQ2 = true;
        this.highlightQ5 = true;
        this.highlightQ8 = true;
      }, interval * 5);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ2 = false;
        this.highlightQ5 = false;
        this.highlightQ8 = false;
        this.highlightQ3 = true;
        this.highlightQ6 = true;
        this.highlightQ = true;
      }, interval * 6);

      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ3 = false;
        this.highlightQ6 = false;
        this.highlightQ = false;
      }, interval * 7);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ = true;
      }, interval * 8);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightQ = false;
      }, interval * 9);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = true;
      }, interval * 10);
      setTimeout(() => {
        if (!this.demoMode) {
          this.reset();
          return;
        }
        this.highlightAnswers = false;
      }, interval * 11);
      setTimeout(() => {
        this.showTopBubble = this.bubbleHeader !== "";
        this.showNext = true;
        this.showReplay = true;
        this.demoMode = false;
      }, interval * 12);
    },
    answer(selectedAnswer) {
      if (this.demoMode) {
        if (!this.showIncorrectAnimation) {
          this.showIncorrectAnimation = true;
          setTimeout(() => {
            this.showIncorrectAnimation = false;
          }, 500);
        }
        return;
      }

      this.selectedAnswer = selectedAnswer;

      this.showContinueBubble = !this.showPC && this.hasCorrectAnswer();

      if (this.showAnswer) {
        this.highlightQ = true;
        this.highlightA1 = selectedAnswer === 1;
        this.highlightA2 = selectedAnswer === 2;
        this.highlightA3 = selectedAnswer === 3;
        this.highlightA4 = selectedAnswer === 4;
        this.highlightA5 = selectedAnswer === 5;
      }

      if (this.onAnswer !== false) {
        this.onAnswer();
      }
    },
    hasCorrectAnswer() {
      return this.item.answer === this.selectedAnswer;
    },
    highlightColorQuestionmark() {
      return this.highlightQ
        ? this.selectedAnswer === false
          ? "highlight"
          : this.hasCorrectAnswer()
          ? "highlight green"
          : "highlight orange"
        : false;
    },
    highlightCorrectAnswer() {
      if (this.selectedAnswer === 1) {
        this.questionMarkImage = this.item.a1;
      } else if (this.selectedAnswer === 2) {
        this.questionMarkImage = this.item.a2;
      } else if (this.selectedAnswer === 3) {
        this.questionMarkImage = this.item.a3;
      } else if (this.selectedAnswer === 4) {
        this.questionMarkImage = this.item.a4;
      } else if (this.selectedAnswer === 5) {
        this.questionMarkImage = this.item.a5;
      }
      setTimeout(() => {
        this.highlightQuestions = false;
        this.highlightQ = false;
        this.highlightA1 = false;
        this.highlightA2 = false;
        this.highlightA3 = false;
        this.highlightA4 = false;
        this.highlightA5 = false;
      }, 500);
    },
    highlightIncorrectAnswer() {
      this.showIncorrectAnimation = true;
      if (!this.selectedAnswer) {
        this.replay();
      }
      setTimeout(() => {
        this.showIncorrectAnimation = false;
      }, 500);
    },
    resolve(item) {
      if (!item) return "";
      return require("./demo/" + item + ".png");
    },
    showAnswerBubble(content) {
      if (!this.selectedAnswer) return;
      if (content[this.selectedAnswer - 1].header === "") return;
      this.bubbleHeader = content[this.selectedAnswer - 1].header;
      this.bubbleText = content[this.selectedAnswer - 1].text;
      this.showTopBubble = true;
    },
  },
  data() {
    return {
      ff: 200,
      screen: 0,
      languageScore: 0,
      languageAnswered: false,
      header: "",
      text: "",
      bubbleHeader: "",
      bubbleText: "",
      bubbleNext: "",
      isPage: false,
      item: false,
      selectedAnswer: false,
      questionMarkImage: "result",
      showFS: false,
      showFA: false,
      showPC: false,
      showDemo: false,
      showXDemo: false,
      showNext: false,
      showPrevious: false,
      showReplay: false,
      showTopBubble: false,
      showContinueBubble: false,
      showIncorrectAnimation: false,
      showCorrectAnswer: true,
      highlightQ: false,
      highlightQ1: false,
      highlightQ2: false,
      highlightQ3: false,
      highlightQ4: false,
      highlightQ5: false,
      highlightQ6: false,
      highlightQ7: false,
      highlightQ8: false,
      highlightQ9: false,
      highlightA1: false,
      highlightA2: false,
      highlightA3: false,
      highlightA4: false,
      highlightA5: false,
      highlightQuestions: false,
      highlightAnswers: false,
      highlightNext: false,
      demoMode: false,
      onAnswer: false,
      items: [
        {
          item: "XFS01",
          answer: 2,
          q1: "XFS01_Q1",
          q2: "XFS01_Q2",
          q3: "XFS01_Q3",
          q4: "XFS01_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS01_A1",
          a2: "XFS01_A2",
          a3: "XFS01_A3",
          a4: "XFS01_A4",
          a5: "",
        },
        {
          item: "XFS02",
          answer: 2,
          q1: "XFS02_Q1",
          q2: "XFS02_Q2",
          q3: "XFS02_Q3",
          q4: "XFS02_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS02_A1",
          a2: "XFS02_A2",
          a3: "XFS02_A3",
          a4: "XFS02_A4",
          a5: "",
        },
        {
          item: "XFS03",
          answer: 1,
          q1: "XFS03_Q1",
          q2: "XFS03_Q2",
          q3: "XFS03_Q3",
          q4: "XFS03_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS03_A1",
          a2: "XFS03_A2",
          a3: "XFS03_A3",
          a4: "XFS03_A4",
          a5: "",
        },
        {
          item: "XFA01",
          answer: 2,
          q1: "XFA01_Q1",
          q2: "XFA01_Q2",
          q3: "XFA01_Q3",
          q4: "XFA01_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA01_A1",
          a2: "XFA01_A2",
          a3: "XFA01_A3",
          a4: "XFA01_A4",
          a5: "",
        },
        {
          item: "XFA02",
          answer: 4,
          q1: "XFA02_Q1",
          q2: "XFA02_Q2",
          q3: "XFA02_Q3",
          q4: "XFA02_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA02_A1",
          a2: "XFA02_A2",
          a3: "XFA02_A3",
          a4: "XFA02_A4",
          a5: "",
        },
        {
          item: "XFA03",
          answer: 4,
          q1: "XFA03_Q1",
          q2: "XFA03_Q2",
          q3: "XFA03_Q3",
          q4: "XFA03_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA03_A1",
          a2: "XFA03_A2",
          a3: "XFA03_A3",
          a4: "XFA03_A4",
          a5: "",
        },
        {
          item: "XPC01",
          answer: 1,
          q1: "XPC01_Q1",
          q2: "XPC01_Q2",
          q3: "XPC01_Q3",
          q4: "XPC01_Q4",
          q5: "XPC01_Q5",
          q6: "XPC01_Q6",
          q7: "XPC01_Q7",
          q8: "XPC01_Q8",
          a1: "XPC01_A1",
          a2: "XPC01_A2",
          a3: "XPC01_A3",
          a4: "XPC01_A4",
          a5: "XPC01_A5",
        },
        {
          item: "XPC02",
          answer: 3,
          q1: "XPC02_Q1",
          q2: "XPC02_Q2",
          q3: "XPC02_Q3",
          q4: "XPC02_Q4",
          q5: "XPC02_Q5",
          q6: "XPC02_Q6",
          q7: "XPC02_Q7",
          q8: "XPC02_Q8",
          a1: "XPC02_A1",
          a2: "XPC02_A2",
          a3: "XPC02_A3",
          a4: "XPC02_A4",
          a5: "XPC02_A5",
        },
        {
          item: "XPC03",
          answer: 5,
          q1: "XPC03_Q1",
          q2: "XPC03_Q2",
          q3: "XPC03_Q3",
          q4: "XPC03_Q4",
          q5: "XPC03_Q5",
          q6: "XPC03_Q6",
          q7: "XPC03_Q7",
          q8: "XPC03_Q8",
          a1: "XPC03_A1",
          a2: "XPC03_A2",
          a3: "XPC03_A3",
          a4: "XPC03_A4",
          a5: "XPC03_A5",
        },
        {
          item: "DEMO",
          answer: 1,
          q1: "DM001_D1",
          q2: "DM001_D2",
          q3: "DM001_D3",
          q4: "DM001_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
        },
        {
          item: "DEMO",
          answer: 1,
          q1: "XFS06_Q1",
          q2: "XFS06_Q2",
          q3: "XFS06_Q3",
          q4: "XFS06_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
        },
        {
          item: "DEMO",
          answer: 1,
          q1: "DM002_D1",
          q2: "DM002_D2",
          q3: "DM002_D3",
          q4: "DM002_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
        },
        {
          item: "DEMO",
          answer: 1,
          q1: "DM003_D1",
          q2: "DM003_D2",
          q3: "DM003_D3",
          q4: "DM003_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
        },
        {
          item: "DEMO",
          answer: 1,
          q1: "DM004_D1",
          q2: "DM004_D2",
          q3: "DM004_D3",
          q4: "DM004_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "",
          a2: "",
          a3: "",
          a4: "",
          a5: "",
        },
        {
          item: "XFS04",
          answer: 2,
          q1: "XFS04_Q1",
          q2: "XFS04_Q2",
          q3: "XFS04_Q3",
          q4: "XFS04_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS04_A1",
          a2: "XFS04_A2",
          a3: "XFS04_A3",
          a4: "XFS04_A4",
          a5: "",
        },
        {
          item: "XFS05",
          answer: 3,
          q1: "XFS05_Q1",
          q2: "XFS05_Q2",
          q3: "XFS05_Q3",
          q4: "XFS05_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS05_A1",
          a2: "XFS05_A4",
          a3: "XFS05_A2",
          a4: "XFS05_A3",
          a5: "",
        },
        {
          item: "XFA04",
          answer: 1,
          q1: "XFA04_Q1",
          q2: "XFA04_Q2",
          q3: "XFA04_Q3",
          q4: "XFA04_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA04_A3",
          a2: "XFA04_A1",
          a3: "XFA04_A2",
          a4: "XFA04_Q3",
          a5: "",
        },
        {
          item: "XFA05",
          answer: 4,
          q1: "XFA05_Q1",
          q2: "XFA05_Q2",
          q3: "XFA05_Q3",
          q4: "XFA05_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA05_A1",
          a2: "XFA05_A2",
          a3: "XFA05_A3",
          a4: "XFA05_A4",
          a5: "",
        },
        {
          item: "XPC04",
          answer: 5,
          q1: "XPC04_Q1",
          q2: "XPC04_Q2",
          q3: "XPC04_Q3",
          q4: "XPC04_Q4",
          q5: "XPC04_Q5",
          q6: "XPC04_Q6",
          q7: "XPC04_Q7",
          q8: "XPC04_Q8",
          a1: "XPC04_A1",
          a2: "XPC04_A2",
          a3: "XPC04_A3",
          a4: "XPC04_A4",
          a5: "XPC04_A5",
        },
        {
          item: "XPC05",
          answer: 1,
          q1: "XPC05_Q1",
          q2: "XPC05_Q2",
          q3: "XPC05_Q3",
          q4: "XPC05_Q4",
          q5: "XPC05_Q5",
          q6: "XPC05_Q6",
          q7: "XPC05_Q7",
          q8: "XPC05_Q8",
          a1: "XPC05_A1",
          a2: "XPC05_A2",
          a3: "XPC05_A3",
          a4: "XPC05_A4",
          a5: "XPC05_A5",
        },
        {
          item: "XDEMO",
          answer: 4,
          q1: "XFS06_Q1",
          q2: "XFS06_Q2",
          q3: "XFS06_Q3",
          q4: "XFS06_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "Shape",
          a2: "Position",
          a3: "Number",
          a4: "Size",
          a5: "",
        },
        {
          item: "XDEMO",
          answer: 3,
          q1: "DM003_D1",
          q2: "DM003_D2",
          q3: "DM003_D3",
          q4: "DM003_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "Shape",
          a2: "Position",
          a3: "Number",
          a4: "Size",
          a5: "",
        },
        {
          item: "XDEMO",
          answer: 1,
          q1: "DM001_D1",
          q2: "DM001_D2",
          q3: "DM001_D3",
          q4: "DM001_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "Shape",
          a2: "Position",
          a3: "Number",
          a4: "Size",
          a5: "",
        },
        {
          item: "XDEMO",
          answer: 2,
          q1: "DM002_D1",
          q2: "DM002_D2",
          q3: "DM002_D3",
          q4: "DM002_D4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "Shape",
          a2: "Position",
          a3: "Number",
          a4: "Size",
          a5: "",
        },
        {
          item: "XFS10",
          answer: 2,
          q1: "XFS10_Q1",
          q2: "XFS10_Q2",
          q3: "XFS10_Q3",
          q4: "XFS10_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS10_A1",
          a2: "XFS10_A2",
          a3: "XFS10_A3",
          a4: "XFS10_A4",
          a5: "",
        },
        {
          item: "XFA06",
          answer: 3,
          q1: "XFA06_Q1",
          q2: "XFA06_Q2",
          q3: "XFA06_Q3",
          q4: "XFA06_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA06_A1",
          a2: "XFA06_A2",
          a3: "XFA06_A3",
          a4: "XFA06_A4",
          a5: "",
        },
        {
          item: "XPC06",
          answer: 5,
          q1: "XPC06_Q1",
          q2: "XPC06_Q2",
          q3: "XPC06_Q3",
          q4: "XPC06_Q4",
          q5: "XPC06_Q5",
          q6: "XPC06_Q6",
          q7: "XPC06_Q7",
          q8: "XPC06_Q8",
          a1: "XPC06_A1",
          a2: "XPC06_A2",
          a3: "XPC06_A3",
          a4: "XPC06_A4",
          a5: "XPC06_A5",
        },
        {
          item: "XPC07",
          answer: 4,
          q1: "XPC07_Q1",
          q2: "XPC07_Q2",
          q3: "XPC07_Q3",
          q4: "XPC07_Q4",
          q5: "XPC07_Q5",
          q6: "XPC07_Q6",
          q7: "XPC07_Q7",
          q8: "XPC07_Q8",
          a1: "XPC07_A1",
          a2: "XPC07_A2",
          a3: "XPC07_A3",
          a4: "XPC07_A4",
          a5: "XPC07_A5",
        },
        {
          item: "XFS11",
          answer: 1,
          q1: "XFS11_Q1",
          q2: "XFS11_Q2",
          q3: "XFS11_Q3",
          q4: "XFS11_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFS11_A1",
          a2: "XFS11_A2",
          a3: "XFS11_A3",
          a4: "XFS11_A4",
          a5: "",
        },
        {
          item: "XFA07",
          answer: 4,
          q1: "XFA07_Q1",
          q2: "XFA07_Q2",
          q3: "XFA07_Q3",
          q4: "XFA07_Q4",
          q5: "",
          q6: "",
          q7: "",
          q8: "",
          a1: "XFA07_A1",
          a2: "XFA07_A2",
          a3: "XFA07_A3",
          a4: "XFA07_A4",
          a5: "",
        },
        {
          item: "XPC08",
          answer: 5,
          q1: "XPC08_Q1",
          q2: "XPC08_Q2",
          q3: "XPC08_Q3",
          q4: "XPC08_Q4",
          q5: "XPC08_Q5",
          q6: "XPC08_Q6",
          q7: "XPC08_Q7",
          q8: "XPC08_Q8",
          a1: "XPC08_A1",
          a2: "XPC08_A2",
          a3: "XPC08_A3",
          a4: "XPC08_A4",
          a5: "XPC08_A5",
        },
      ],
    };
  },
};
</script>

<style scoped>
@media print {
  .container {
    position: static !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    height: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    border: none;
    border: 1px solid red;
  }

  .info-panel {
    display: none;
  }
}

.hidden {
  display: none;
}

.lpcat {
  height: 100%;
  background: #303030;
  background: url(../../assets/img/background.jpg) center repeat scroll;
  color: #c3c3c3;
  font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-weight: 400;
}

h1 {
  color: #fff;
  font-size: 60px;
  line-height: 72px;
  font-weight: 300;
  margin-bottom: 36px;
}
h2 {
  color: #61ae24;
  font-size: 36px;
  line-height: 36px;
  font-weight: 300;
  margin-bottom: 18px;
}
h3 {
  color: #61ae24;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 16px;
}
h4 {
  color: #61ae24;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 6px;
}
h5 {
  color: #c3c3c3;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
}
h6 {
  color: #c3c3c3;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
}
p {
  margin-bottom: 12px;
}
ul {
  color: red;
  list-style: inside !important;
  list-style-type: circle !important;
}
li {
  color: red;
}
a {
  color: #61ae24;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}
a:hover {
  color: #6dc129;
}
.color {
  color: #61ae24;
}
.blue {
  color: #00a1cb;
}
.green {
  color: #61ae24;
}
.orange {
  color: #f18d05;
}
.red {
  color: #e54028;
}
.hide {
  display: none;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: 16px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #fff;
  font-size: 18px;
  text-shadow: 0 1px 2px #222;
  background-color: #616161;
  margin: 0;
  padding: 0 10px;
  width: 193px;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: background-color 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
}
button.active,
button:hover {
  background-color: #61ae24;
}
label {
  color: #c3c3c3;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
}
input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: 18px/24px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  font-weight: 300;
  color: #61ae24;
  margin: 0;
  padding: 3px;
  background-color: #424242;
  border: 1px solid #424242;
  border-top-color: #353535;
  border-right-color: #404040;
  border-bottom-color: #5c5c5c;
  border-left-color: #404040;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.line {
  background: url(../../assets/img/line.png) center center no-repeat;
  height: 4px;
  margin-top: 48px;
  margin-bottom: 48px;
}

/* Layout */

.container {
  width: 956px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -478px;
  margin-top: -301px;
  /*display: none;*/
}

.container.show {
  display: block;
}
.page {
  background-color: #3e3e3e;
  height: 600px;
  padding: 40px 62px 114px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  position: relative;
}
.page.nobuttons {
  min-height: 494px;
  padding-bottom: 56px;
}
.quit,
.prev,
.next,
.thumbs-up,
.thumbs-down,
.settings,
.help {
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 31px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: background-color 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
}
.refresh {
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 31px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.3),
    0 2px 2px rgba(0, 0, 0, 0.4), 0 0 4px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: background-color 0.15s ease-in-out;
  -moz-transition: background-color 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
}
.quit {
  background: url(../../assets/img/quit.png) center center no-repeat #616161;
  background-image: url(../../assets/img/quit.svg), none;
  left: 31px;
}
.quit:hover {
  background-color: #545454;
}
.prev {
  background: url(../../assets/img/prev.png) center center no-repeat #616161;
  background-image: url(../../assets/img/prev.svg), none;
  left: 31px;
}
.prev:hover {
  background-color: #545454;
}
.prev.orange {
  background: url(../../assets/img/prev.png) center center no-repeat #f18d05;
  background-image: url(../../assets/img/prev.svg), none;
  left: 31px;
}
.prev.orange:hover {
  background-color: #f7981b;
}
.next {
  background: url(../../assets/img/next.png) center center no-repeat #61ae24;
  background-image: url(../../assets/img/next.svg), none;
  right: 31px;
}
.next:hover {
  background-color: #6dc129;
}
.thumbs-up {
  background: url(../../assets/img/thumbs-up.png) center center no-repeat
    #616161;
  background-image: url(../../assets/img/thumbs-up.svg), none;
}
.thumbs-down {
  background: url(../../assets/img/thumbs-down.png) center center no-repeat
    #616161;
  background-image: url(../../assets/img/thumbs-down.svg), none;
}
.thumbs-up.selected {
  background-color: #61ae24;
}
.thumbs-down.selected {
  background-color: #e54028;
}
.thumbs-up:hover {
  background-color: #6dc129;
}
.thumbs-down:hover {
  background-color: #e64f3b;
}
.help {
  background: url(../../assets/img/help.png) center center no-repeat #333333;
  background-image: url(../../assets/img/help.svg), none;
  left: 31px;
}
.refresh {
  background: url(../../assets/img/refresh.png) center center no-repeat #333333;
  background-image: url(../../assets/img/refresh.svg), none;
  left: 31px;
}
.help:hover,
.refresh:hover {
  background-color: #3e3e3e;
}

/* Settings */

.settings {
  background: url(../../assets/img/settings-61.png) right 8px no-repeat #3e3e3e;
  background-image: url(../../assets/img/settings-61.svg), none;
  height: 68px;
  width: auto;
  min-width: 60px;
  position: fixed;
  top: -8px;
  right: 16px;
}
.settings:hover {
  background-color: #383838;
}
.settings .toggle {
  width: 60px;
  height: 61px;
  margin-top: 7px;
  right: 0;
  top: 0;
  cursor: pointer;
  float: right;
}
.settings select {
  background-color: #323232;
  margin: 21px 20px 0;
  float: left;
  display: none;
}

/* Inline icons */

span.inline {
  width: 21px;
  height: 16px;
  margin: 0 2px;
  display: inline-block;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.spannext {
  background: url(../../assets/img/next.png);
  background-image: url(../../assets/img/next.svg), none;
}
.spanprev {
  background: url(../../assets/img/prev.png);
  background-image: url(../../assets/img/prev.svg), none;
}

/* Test LPCAT Global */

.leftside,
.rightside {
  width: 68px;
  height: 620px;
  float: left;
  position: relative;
}
.middle {
  width: 820px;
  height: 620px;
  float: left;
  position: relative;
}
.leftside .help,
.leftside .refresh {
  top: 6px;
  left: 0;
}
.rightside .next {
  bottom: 6px;
  right: 0;
}
.leftside .prev {
  bottom: 6px;
  left: 0;
}
.questions,
.answers {
  border: 2px solid transparent;
  -webkit-transform: translateZ(0);
  transition: border 0.3s;
  border-radius: 22px;
}
.questions > div,
.answers > div {
  width: 144px;
  height: 144px;
  padding: 6px;
  float: left;
  position: relative;
  border: 2px solid transparent;
  border-radius: 22px;
  -webkit-transform: translateZ(0);
  transition: border 0.3s;
}
.questions > div:before,
.answers > div:before {
  content: "";
  position: absolute;
  width: 124px;
  height: 124px;
  top: 8px;
  left: 8px;
  background-color: #3e3e3e;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.95),
    inset 0 -1px 2px rgba(255, 255, 255, 0.15);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.questions > div img,
.answers > div img,
.questions > div span,
.answers > div span {
  position: absolute;
  display: inline-block;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
  transform: border 0.3s;
  cursor: pointer;
  color: white;
  font-size: 18px;
}

.answers img {
  cursor: pointer;
}

/* Test fs */

.test-fs {
  height: 620px;
  margin-top: -9px;
}
.test-fs .questions {
  height: 146px;
  width: 728px;
  margin: 140px auto 15px auto;
}
.test-fs .questions div {
  margin-right: 1px;
}
.test-fs .questions div:last-child {
  margin-right: 0;
}
.test-fs .answers {
  height: 146px;
  width: 728px;
  margin: 15px auto 0 auto;
}
.test-fs .answers div {
  margin-right: 49px;
}
.test-fs .answers div:last-child {
  margin-right: 0;
}
.test-fs.demo .questions div {
  margin-right: 49px;
}
.test-fs.demo .questions div:last-child {
  margin-right: 0;
}
/* Test FA */

.test-fa {
  height: 620px;
  margin-top: -9px;
}
.test-fa .questions {
  background: url(../../assets/img/dots.png) no-repeat 16px 8px;
  height: 146px;
  width: 728px;
  margin: 140px auto 15px auto;
}
.test-fa .questions div {
  margin-right: 31px;
}
.test-fa .questions div:nth-child(1) {
  margin-right: 35px;
}
.test-fa .questions div:nth-child(2) {
  margin-right: 82px;
}
.test-fa .questions div:last-child {
  margin-right: 0;
}
.test-fa .answers {
  height: 146px;
  width: 728px;
  margin: 15px auto 0 auto;
}
.test-fa .answers div {
  margin-right: 49px;
}
.test-fa .answers div:last-child {
  margin-right: 0;
}

/* Test PC */

.test-pc {
  height: 620px;
  margin-top: -9px;
}
.test-pc .questions {
  height: 435px;
  width: 439px;
  margin: 0 auto 10px auto;
}
.test-pc .questions div {
  margin-right: 1px;
  margin-bottom: 1px;
}
.test-pc .answers {
  height: 146px;
  width: 728px;
  margin: 10px auto 0 auto;
}
.test-pc .answers div {
  margin-right: 1px;
}
.test-pc .answers div:last-child {
  margin-right: 0;
}

/* Highlights */

.highlight {
  border: 2px solid yellow !important;
  -webkit-box-shadow: inset 0 0 5px yellow;
  -moz-box-shadow: inset 0 0 5px yellow;
  box-shadow: inset 0 0 5px yellow;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
}
.highlight.green {
  border: 2px solid #6dc129 !important;
  -webkit-box-shadow: inset 0 0 5px #6dc129;
  -moz-box-shadow: inset 0 0 5px #6dc129;
  box-shadow: inset 0 0 5px #6dc129;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  border-radius: 22px;
}
.highlight.orange {
  border: 2px dotted #f18d05 !important;
  -webkit-box-shadow: inset 0 0 5px #f18d05;
  -moz-box-shadow: inset 0 0 5px #f18d05;
  box-shadow: inset 0 0 5px #f18d05;
}
img.highlight {
  margin-top: -2px;
  margin-left: -2px;
}
.highlight.single {
  width: 136px;
  height: 136px;
}
.highlight.row {
  width: 700px;
  height: 136px;
}
.next .highlight {
  display: block;
  position: absolute;
  top: -16px;
  bottom: -16px;
  left: -16px;
  right: -16px;
}

.screen-box {
  position: fixed;
  top: 16px;
  left: 16px;
  min-width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 4px;
  /* box-shadow: inset 0 1px 2px rgba(0,0,0,0.95), inset 0 -1px 2px rgba(255,255,255,0.15); */
  color: yellow;
  background-color: #3e3e3e;
  text-shadow: none;
  font-size: 12px;
}

/* Bubbles */

.bubble {
  position: absolute;
  background-color: rgba(97, 174, 36, 0.1);
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  padding: 8px 12px;
  margin-top: -9px;
  margin-left: -8px;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
.bubble:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
}
.bubble.above {
  height: 100px;
  top: 7px;
}
.bubble.above:after {
  top: 100%;
  border-left: 12px solid transparent;
  border-top: 12px solid rgba(97, 174, 36, 0.1);
  border-right: 12px solid transparent;
}
.bubble.below {
  height: 100px;
  bottom: 7px;
}
.bubble.below:after {
  bottom: 100%;
  border-left: 12px solid transparent;
  border-bottom: 12px solid rgba(97, 174, 36, 0.1);
  border-right: 12px solid transparent;
}
.bubble.left {
  width: 86px;
  left: 7px;
}
.bubble.left:after {
  left: 100%;
  border-bottom: 18px solid transparent;
  border-left: 14px solid rgba(97, 174, 36, 0.1);
  border-top: 18px solid transparent;
  margin-left: 0px;
}
.bubble.right {
  width: 86px;
  right: 7px;
}
.bubble.right:after {
  right: 100%;
  border-top: 12px solid transparent;
  border-right: 12px solid rgba(97, 174, 36, 0.1);
  border-bottom: 12px solid transparent;
}
.bubble.full {
  width: 702px;
  left: 132px;
  max-height: 132px;
  height: auto;
  min-height: 120px !important;
  z-index: 11;
}
.bubble.full:after {
  left: 630px;
}
.bubble.full.showfa {
  /* width: 686px; */
  /* left: 136px; */
}
.bubble.full.showfa:after {
  /* left: 610px; */
}
.bubble.full.showpc {
  width: 250px;
  max-height: 416px !important;
  top: 10px;
  left: auto;
  right: 0px;
  z-index: 11;
}
.bubble.full.showpc:after {
  left: -24px !important;
  top: 50px !important;
}
.bubble.full.showdemo:after {
  display: none;
}
.bubble.nextArrow.textPage {
  left: auto;
  right: 120px;
  top: 532px;
}
.bubble.nextArrow {
  width: auto;
  left: auto;
  right: 90px;
  top: 564px;
  z-index: 1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.bubble.nextArrow:after {
  top: 0px;
}
/* Bubble Widths */

.w200 {
  width: 200px;
}
.w200:after {
  left: 100px;
}
.w300 {
  width: 300px;
}
.w300:after {
  left: 150px;
}
.w400 {
  width: 400px;
}
.w400:after {
  left: 200px;
}

/* Bubble heights */

.h40 {
  height: 40px;
}
.h40:after {
  top: 20px;
}
.h60 {
  height: 60px;
}
.h60:after {
  top: 30px;
}
.h80 {
  height: 80px;
}
.h80:after {
  top: 40px;
}
.h100 {
  height: 100px;
}
.h100:after {
  top: 50px;
}
.h120 {
  height: 120px;
}
.h120:after {
  top: 60px;
}
.h140 {
  height: 140px;
}
.h140:after {
  top: 70px;
}
.h160 {
  height: 160px;
}
.h160:after {
  top: 80px;
}
.h180 {
  height: 180px;
}
.h180:after {
  top: 90px;
}
.h200 {
  height: 200px;
}
.h200:after {
  top: 100px;
}

/* Shake */

.wrong {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* End */
</style>
